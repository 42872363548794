// 用户管理
import { get,del,post,put } from "../utils/http";

//获取用户列表
export function getUserList(params) {
  return get('/customer/user/page',params)
}
//创建用户
 export function createUser(params) {
  return post('/customer/user',params)
}
//编辑用户
export function editUser(params) {
  return put('/customer/user',params)
}
//删除用户
export function deleteUser(userId) {
  return del(`/customer/user/${userId}`)
}
//获取企业所有模块
export function getModuleList() {
  return get('/customer/module/list')
}
//获取用户已授权模块
 export function getModule(params) {
  return get('/customer/user/module/list/to-customer',params)
}
//授权分配
export function authorizeModule(params) {
  return put('/customer/user/module/authorize',params)
}
//授权禁止
export function disableModule(params) {
  return put('/customer/user/module/prohibit',params)
}