<template>
  <div class="user_edit">
    <div class="content">
      <span class="title">{{routeData.id?'编辑':'添加'}}用户信息</span>
      <el-form :model="form" :rules="rules" class="info" ref="form">
        <el-form-item prop="name" class="item">
          <label>姓名：</label>
          <el-input v-model="form.name" clearable/>
        </el-form-item>
        <el-form-item prop="phone" class="item">
          <label>手机号：</label>
          <el-input v-model="form.phone" clearable/>
        </el-form-item>
        <el-form-item prop="website" class="item">
          <label>用户状态：</label>
          <div>
            <el-switch
              v-model="form.status"
              active-color="#2DEB79"
              inactive-color="#B9B9B9"
              :active-value="0"
              :inactive-value="1"
            />
            <span style="margin-left:10px;color:#B9B9B9">{{form.status==0?'启用':'禁用'}}</span>
          </div>
        </el-form-item>
      </el-form>
      <!-- <div class="btnList" v-if="routeData.id">
        <el-button type="primary" size="small" @click="handleSubmit">确认</el-button>
      </div> -->
    </div>
    <div class="power" v-if="routeData.id">
       <span class="title">模块权限分配</span>
       <div class="powerItems">
        <div class="item" v-for="item in powerLists" :key="item.moduleName">
          <img :src="powerSrc[item.moduleName]"/>
          <span>{{item.moduleName}}</span>
          <el-checkbox v-model="item.checked" @change="changeModules($event,item)"/>
        </div>
       </div>
    </div>
    <div class="footer">
      <el-button size="small" @click="goRoute">取消</el-button>
      <el-button size="small" type="primary" @click="checkFormSubmit()">确定</el-button>
    </div>
  </div>
</template>

<script>
import { 
  createUser,
  editUser,
  getModule,
  authorizeModule,
  disableModule,
  getModuleList
  } from '@/api/usermanagement'
export default {
  name: "user_edit",
  data() {
    return {
      routeData:{},
      form:{
        name:'',
        phone:'',
        status:1
      },
      rules:{
        name: [
          { required: true, message: '请输入姓名', trigger: ['blur','change'] }
        ],
        phone:[
          { required: true, message: '请输入电话', trigger: ['blur','change'] },
          { pattern:new RegExp("^(?:(?:\\+|00)86)?1[3-9]\\d{9}$|^0\\d{2,3}-?\\d{7,8}$"), message: "请输入合法手机号/电话号", trigger: ['blur','change'] }
        ]
      },
      powerLists:[
        // {
        //   id:1,
        //   icon:require('@/assets/images/common/tos.png'),
        //   moduleName:'机器人调度',
        //   checked:false
        // },{
        //   id:2,
        //   icon:require('@/assets/images/common/xos.png'),
        //   moduleName:'XOS管理',
        //   checked:false
        // },{
        //   id:3,
        //   icon:require('@/assets/images/common/aps.png'),
        //   moduleName:'APS先进排程',
        //   checked:false
        // },{
        //   id:4,
        //   icon:require('@/assets/images/common/mes.png'),
        //   moduleName:'MES',
        //   checked:false
        // },{
        //   id:5,
        //   icon:require('@/assets/images/common/wms.png'),
        //   moduleName:'WMS',
        //   checked:false
        // }
      ],
      powerSrc:{
        WMS:require('@/assets/images/common/wms.png'),
        MES:require('@/assets/images/common/mes.png'),
        'APS先进排程':require('@/assets/images/common/aps.png'),
        'XOS管理':require('@/assets/images/common/xos.png'),
        '机器人调度':require('@/assets/images/common/tos.png')
      }
    };
  },
 async activated() {
    this.getModuleLists()
    this.routeData = this.$route.query
    if(this.routeData.id){
      this.form = {
        ...this.routeData,
        status:Number(this.routeData.status)
      }
      await this.getModuleDetails()
    }
  },
  deactivated(){
    Object.assign(this.$data,this.$options.data())
    this.$nextTick(()=>{
      this.$refs.form.clearValidate()
    })
  },
  methods:{
    goRoute(){
      this.$router.go(-1)
    },
    //获取用户已授权列表
    getModuleDetails(){
      let params = {
        userId:this.routeData.id
      }
      getModule(params).then(res=>{
        if(res.code==200){
          let power = res.data.map(v=>v.moduleName)
          this.powerLists.forEach((v,i)=>{
            this.$set(this.powerLists[i],'checked',power.includes(v.moduleName))
          })
        }else{
          this.$message.error(res.msg || '接口错误')
        }
      })
    },
    // 获取所有模块列表
    getModuleLists(){
      let params = {
        orgId:this.routeData.id
      }
      getModuleList(params).then(res=>{
        if(res.code==200){
          this.powerLists = res.data
        }
      })
    },
    // 更改用户授权
    changeModules(value,item){
      let params = {
        userId:this.routeData.id,
        moduleId:item.moduleId
      }
      let Api = value ? authorizeModule : disableModule
      Api(params).then(res=>{
        if(res.code==200){
          this.$message({
            type:'success',
            message: `授权${value?'分配':'禁止'}成功`
          })
        }else{
          this.$set(this.powerLists.find(v=>v.moduleId == item.moduleId),'checked',!value)
          this.$message.error(res.msg || '操作失败，请重试')
          this.$forceUpdate()
        }
      })
    },
    // 提交校验
    checkFormSubmit(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.handleSubmit()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 保存用户信息
    handleSubmit(){
      let Api = this.routeData.id ? editUser : createUser
      let {phone,name,status} = this.form
      let params = {
       phone,
       name,
       status,
       userId:this.routeData?.id
      }
      Api(params).then(res=>{
        if(res.code==200){
          this.$message.success('操作成功')
          // !this.routeData.id && this.$router.go(-1)
          this.$router.go(-1)
        }else{
          this.$message.error(res.msg || '操作失败')
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/element-reset.scss';
.user_edit{
  padding:10px 20px;
  position: relative;
  height: calc(100vh - 180px);
  text-align: left;
  color: #E8E8E8;
  .title{
    font-size: 20px;
  }
  .content{
    background: #262626;
    border-radius: 8px;
    padding: 23px 39px;
    label{
      font-size: 16px;
    }
    .info{
      display: flex;
      margin-top: 25px;
      .item{
        margin-right: 10%;
      }
    }
    .btnList{
      margin-top: 20px;
    }
  }
  .power{
    background: #262626;
    border-radius: 8px;
    padding: 23px 39px;
    margin-top: 20px;
    .powerItems{
      display:flex;
      // justify-content: space-between;
      margin: 30px 0px 10px 0px;
      .item{
        margin-right: 180px;
        img{
          width: 20px;
          height: 20px;
          vertical-align: middle;
        }
        span{
          margin: 0px 10px;
        }
      }
    }
  }
  .footer{
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    line-height: 60px;
    background: #262626;
    margin: 0px 2px;
    padding-right: 32px;
  }
}
</style>
<style>
.el-date-table td.in-range div{
  background:#2C2C2C
}
.el-date-range-picker__time-header{
  display: none;
}
.el-date-editor .el-range-input{
  background: none;
}
</style>
